export function translateStatusLote(text) {
    if (!text) return '';
    switch (text.toUpperCase()) {
        case 'UP_FALHA':
            return 'Falha no upload';
        case 'NAO_INICIADO':
            return 'Não iniciado';
        case 'EM_AUDITORIA':
            return 'Em auditoria';
        case 'AUDITADO':
            return 'Auditado';
    }
    return '';
}

export function translateStatusAuditoria(text, comAlteracao) {
    if (!text) return '';

    let lblAlteracao = ''
    if (typeof comAlteracao != 'undefined' && text == 'A') {
        if (comAlteracao) {
            lblAlteracao = ' - Com alterações';
        } else {
            lblAlteracao = ' - Sem alterações';
        }
    }

    let result = translateStatusAuditoriaDataset()[text];
    if (result) {
        return translateStatusAuditoriaDataset()[text] + lblAlteracao;
    }

    return '';
}

export function translateStatusAuditoriaDataset() {
    return {
        'NI': 'Não iniciado',
        'EA': 'Em Auditoria',
        'A': 'Auditado',
        'W': 'Aguardando'
    }
}

export function drawStatusAuditoria(text, comAlteracao) {
  return `
    <div class="alert alert-${translateStatusAuditoriaDatasetColor()[text]} p-2 m-0 text-nowrap " role="alert">
      ${translateStatusAuditoriaDatasetIcon()[text]}
      ${translateStatusAuditoria(text, comAlteracao)}
    </div>
  `;
}

export function translateStatusAuditoriaDatasetColor() {
  return {
      'NI': 'danger',
      'EA': 'warning',
      'A': 'success',
      'W': 'light'
  }
}

export function translateStatusAuditoriaDatasetIcon() {
  return {
      'NI': '<i class="far fa-square me-1"></i>',
      'EA': '<i class="far fa-circle me-1"></i>',
      'A': '<i class="far fa-star me-1"></i>',
      'W': '<i class="fas fa-certificate me-1"></i>'
  }
}

export function statusRadio2Code(statusRadioVal) {
  switch (statusRadioVal) {
    case 'NAO_INICIADO': return 'NI';
    case 'EM_AUDITORIA': return 'EA';
    case 'AUDITADO': return 'A';
    case 'TODOS':
    default: return '';
  }
}

export function statusLoteRadios(addTodos = false, addFalha = false) {
    var values = [
        { value: 'NAO_INICIADO', label: 'Não iniciado' },
        { value: 'EM_AUDITORIA', label: 'Em auditoria' },
        { value: 'AUDITADO', label: 'Auditado' }
    ];
    
    if (addFalha) {
        values.unshift({ value: 'UP_FALHA', label: 'Falha no upload' });
    }
    if (addTodos) {
        values.unshift({ value: 'TODOS', label: 'Todos' });
    }
    return values;
}