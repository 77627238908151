import { api, param2query } from "./api.service";

export const getAllUploads = (params) => {
  return api.get(`${process.env.VUE_APP_APIURL}arquivos/listar?${param2query(params)}`);
};

export const getAllLotes = (params) => {
  return api.get(`${process.env.VUE_APP_APIURL}lotesfaturamento/listar?${param2query(params)}`);
};

export const getLoteDetalhe = (id) => {
  return api.get(`${process.env.VUE_APP_APIURL}lotesfaturamento/lote-detalhe/${id}`);
};

export const exportarLote = (id, filename) => {
  return api.get(`${process.env.VUE_APP_APIURL}lotesfaturamento/exportar/${id}`, { responseType: 'blob' }).then(({ data }) => {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};

export const reabrirAuditoriaLote = (arquivoId, representacaoId) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/lote-detalhe/${arquivoId}/reabrir-auditoria/${representacaoId || ''}`);
}

export const downloadRelatorioAuditoriaFatura = (
  id, usarAssinatura, representacaoEnfId, representacaoMedicoId, comGlosa, comOpme, guiasFiltro, filename
) => {
  return api.get(
    `${process.env.VUE_APP_APIURL}lotesfaturamento/relatorio-auditoria?`+ param2query({
      id,
      glosa: comGlosa ? 'true' : 'false',
      opme: comOpme ? 'true' : 'false',
      representacaoEnfId: representacaoEnfId,
      representacaoMedicoId: representacaoMedicoId,
      usarAssinatura: usarAssinatura ? 'true' : 'false',
      guias: guiasFiltro,
    }),
    { responseType: 'blob' }
  ).then(({ data }) => {
    const href = URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};

export const getGuiaDetalhe = (arquivoId, guiaIndex) => {
  return api.get(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/${arquivoId}/${guiaIndex}`);
}

export const encerrarAuditoriaGuia = (arquivoId, guiaIndex, representacaoId) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/${arquivoId}/${guiaIndex}/encerrar-auditoria/${representacaoId || ''}`);
}

export const encerrarAuditoriaGuiaVarias = (arquivoId, guiaIndexes, usuarioRepresentadoId) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/encerrar-auditorias`, {
    arquivoId, guiaIndexes, usuarioRepresentadoId,
  });
}

export const reabrirAuditoriaGuia = (arquivoId, guiaIndex, representacaoId) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/${arquivoId}/${guiaIndex}/reabrir-auditoria/${representacaoId || ''}`);
}

export const reabrirAuditoriaGuiaVarias = (arquivoId, guiaIndexes, usuarioRepresentadoId) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/reabrir-auditorias`, {
    arquivoId, guiaIndexes, usuarioRepresentadoId,
  });
}

export const removerGuia = (arquivoId, guiaIndex, motivoId, motivoOutro) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/${arquivoId}/${guiaIndex}/remover-guia`, {
    motivoId: motivoId || null,
    motivoOutro
  });
}

export const reativarGuia = (arquivoId, guiaIndex) => {
  return api.put(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-detalhe/${arquivoId}/${guiaIndex}/reativar-guia`);
}

export const getItemGuiaDetalhe = (arquivoId, guiaIndex, itensGuiaIds) => {
  return api.get(`${process.env.VUE_APP_APIURL}lotesfaturamento/guia-item/${arquivoId}/${guiaIndex}/itens-guia?${param2query({ itensGuiaIds })}`);
}

export const calcularItemGuia = (arquivoId, guiaIndex, itemGuiaIds, model) => {
  model.arquivoId = arquivoId;
  model.guiaIndex = guiaIndex;
  model.itemGuiaIds = itemGuiaIds;

  return api.post(`lotesfaturamento/guia-item/calcular`, model)
}

export const getLabelsMotivos = (params) => {
  return api.get("lotesfaturamento/labels-motivos-glosa?" + param2query(params));
};

export const atualizarItemGuia = (encerrarAuditoria, arquivoId, guiaIndex, itemGuiaIds, model) => {
  model.encerrarAuditoria = encerrarAuditoria;
  model.arquivoId = arquivoId;
  model.guiaIndex = guiaIndex;
  model.itemGuiaIds = itemGuiaIds;

  return api.put('lotesfaturamento/guia-item', model)
}

export const pesquisarMotivoPendenciaAuditoria = (query) => {
  if (typeof query == 'object') {
    return api.get('lotesfaturamento/guia-item/buscar-motivos-pendencia-auditoria?' + param2query(query));
  } else {
    return api.get('lotesfaturamento/guia-item/buscar-motivos-pendencia-auditoria?ativo=true&filtro=' + query);
  }
}

export const getAllTabelaDominio = (params, cancelToken) => {
  return api.get('lotesfaturamento/buscar-tabelas-dominio?' + param2query(params), cancelToken);
};

export const getProcedimento = (params, cancelToken) => {
  return api.get('lotesfaturamento/buscar-procedimentos?' + param2query(params), cancelToken);
};

export const getAllRepresentante = (params, cancelToken) => {
  return api.get('lotesfaturamento/listar-representantes?' + param2query(params), cancelToken);
};

export const getAllMotivoRemoverGuia = (params, cancelToken) => {
  return api.get('lotesfaturamento/listar-motivos-remover-guia?' + param2query(params), cancelToken);
};

export const listarTipoGuia = () => {
  return api.get('lotesfaturamento/listar-tipo-guia');
}

export const listarTipoAtendimento = (tipoGuiaId) => {
  if (!tipoGuiaId) return Promise.resolve([]);
  return api.get('lotesfaturamento/listar-tipo-atendimento/' + tipoGuiaId);
}

export const pesquisarConvenioRelGuiasRemovidas = (query, ignorarIds) => {
  return api.get('lotesfaturamento/buscar-convenios-relatorio-guias-removidas?' + param2query({ filtrar: query, ignorarIds }));
}

export const downloadRelGuiasRemovidas = (periodoStart, periodoEnd, convenioIds, lote, tipoGuiaId, filename) => {
  return api.get(
    `${process.env.VUE_APP_APIURL}lotesfaturamento/relatorio-guias-removidas?`+ param2query({
      periodoStart, periodoEnd, convenioIds, lote, tipoGuiaId,
    }),
    { responseType: 'blob' }
  ).then((response) => {
    if (response && response.headers && response.headers['download-filename']) {
      filename = response.headers['download-filename'];
    }

    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(link);
  })
};

export default {
  getAllUploads, getAllLotes, getLoteDetalhe, getGuiaDetalhe, getLabelsMotivos, listarTipoGuia, listarTipoAtendimento,
  downloadRelGuiasRemovidas,
};
